import { graphql } from "gatsby"
import React from "react"
// import Blogs from "../../components/blogs"
import FirstScreen from "../../components/first_screen"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const title = "花火大会マップ"
const description = "花火大会マップは日本全国の花火大会を掲載したアプリです。"

const Index = ({ data, location }) => {
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout location={location} isAbsolute isLangShown>
      <FirstScreen
        title={title}
        description={description}
        pathname={location.pathname}
      />
      {/*
      <Blogs title="記事一覧" posts={posts} />
      */}
    </Layout>
  )
}

export const Head = ({ location: { pathname } }) => (
  <Seo title={title} description={description} pathname={pathname} />
)

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fields: { slug: { regex: "/^(?=.*-ja/).*$/" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY年MM月DD日")
          title
          description
        }
      }
    }
  }
`

export default Index
